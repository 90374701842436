import axios from 'axios'
import { Message, MessageBox } from 'element-ui'  //导入element-ui组件库
 import { Session,Local } from '@/utils/storage';
 import router from '@/router/index';
// 创建axios的对象
const instance = axios.create({
    baseURL: "https://tjspkf.city-brain.com.cn/api/call/v1/",  //配置固定域名
	// baseURL: "https://call.253.huanxingweb.com/api/call/v1/",  //配置固定域名
	
    timeout: 5000
})
 
// 请求拦截
// 所有的网络请求都会走这个方法,可以在请求添加自定义内容
instance.interceptors.request.use(
    function (config) {
        config.headers['x-api-key'] = Local.get('token')// 请求头添加token值
        // config.headers.info = 'lxy'  //请求头添加info值
        return config
    },
    function (err) {
        return Promise.request(err)
    }
)
 
// 响应拦截
// 此处可以根据服务器返回的状态码做相应的数据
instance.interceptors.response.use(
    function (response) {
        const res = response.data;
        // console.log(res)
        if (res.code && res.code !== 200) {
			if (res.code === 422) {
			    // MessageBox.alert('系统未登录，请重新登录', '错误', {
			    //     confirmButtonText: '确定',
			    //     type: 'error'
			    // }).then(() => {
			    //     store.dispatch('FedLogOut').then(() => {
			    //         location.reload()
			    //     })
			    // })
			    return Promise.reject(response.data)
			}
			 else if (res.code === 500) {
				 Local.clear()
				 router.push({
				 	path:'/login'
				 })
			    MessageBox.alert('系统内部错误，请联系管理员维护', '错误', {
			        confirmButtonText: '确定',
			        type: 'error'
			    })
			
			    return Promise.reject('error')
			}else if(res.code===401){
				// MessageBox.alert('您的登录验证已经过期，请重新登录', '错误', {
				//     confirmButtonText: '确定',
				//     type: 'error'
				// })
				Local.clear()
				router.push({path:'login'})
				return Promise.reject(response.data)
			}
		}
        else{
			return response.data;
		}
    },
    function (err) {
        return Promise.request(err)
    }
)
 
// 封装get和post请求
export function get(url, params) {
    return instance.get(url, {params})
}
 
export function post(url, data) {
    return instance.post(url, data)
}
 
export default instance;