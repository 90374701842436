<template>
  <div class="login">
  	<div class="login-weaper ">
  		<div class="login-left zwyHeightThree">
  			<div class="login-time">{{ time.txt }}</div>
  			<div class="login-left-box">
  				<div>
  					<div class='dis-ali jc_center  '>
  						<img src="../../src/assets/login_logo.png" style="width: 180px;margin-bottom: 40px;" class='leftlogo ' alt="">
  					</div>
  					<!-- <div class="logo dis-ali jc_center mt5 mb5">坐席登录</div> -->
  					<h2 class="title">天津·TianJin</h2>
  					<div class="msg">
  						<div class="msg-author">
  							<span>{{ quotations.name }}</span>
  							<span>{{ quotations.comeFrom }}</span>
  						</div>
  						<div class="msg-txt">{{ quotations.content }}</div>
  					</div>
  				</div>
  			</div>
  		</div>
  		<div class="login-right ">
  			<div class="login-main">
  				<div class='dis-ali jc_center'>
  					<!-- <img src="../../assets/logo.png" style="width: 140px;" class='rightlogo' hover-class='zwyHover3' alt=""> -->
  				</div>
  				<h4 class="login-title">视频客服坐席系统</h4>
  				<el-form class="el-form login-form">
  					<el-form-item style="margin-left: 0px" prop="username">
  						<el-input
  							type="text"
  							placeholder="请输入工号"
  							prefix-icon="el-icon-user"
  							v-model="ruleForm.username"
  							clearable
  							autocomplete="off"
  						>
  						</el-input>
  					</el-form-item>
  					<el-form-item style="margin-left: 0px" prop="password">
  						<el-input
  							type="password"
  							placeholder="请输入密码"
  							prefix-icon="el-icon-lock"
  							v-model="ruleForm.password"
  							autocomplete="off"
  							:show-password="true"
  						>
  						</el-input>
  					</el-form-item>
  					<!-- <el-form-item style="margin-left: 0px" prop="code">
  						<div class="el-row" span="24">
  							<div class="el-col el-col-16">
  								<el-input
  									type="text"
  									maxlength="4"
  									:placeholder="$t('message.login.placeholder3')"
  									prefix-icon="el-icon-position"
  									v-model="ruleForm.code"
  									clearable
  									autocomplete="off"
  								></el-input>
  							</div>
  							<div class="el-col el-col-8">
  								<div class="login-code">
  									<span class="login-code-img">1234</span>
  								</div>
  							</div>
  						</div>
  					</el-form-item> -->
  					<el-form-item style="margin: 40px 0px 0">
  						<el-button type="success" class="login-submit " @click='login' :loading="submit.loading">
  							<span >登录</span>
  						</el-button>
  					</el-form-item>
  				</el-form>
  				<div class="login-menu">
  					<!-- <el-button type="primary" class="login-submit" @click='login' :loading="submit.loading">
  						<span>{{ $t('message.login.btnText') }}</span>
  					</el-button> -->
  					<!-- <a href="javascript:;" >登录</a> -->
  					<!-- <a href="javascript:;">{{ $t('message.login.link.one2') }}</a> -->
  				</div>
  			</div>
  		</div>
  	</div>
  	<div class="vue-particles">
  		<vue-particles color="#dedede" shapeType="star" linesColor="#dedede" hoverMode="grab" clickMode="push" style="height: 100%"></vue-particles>
  	</div>
  </div>
</template>


<script>
import  {quotationsList} from './mock.js';
import { Session,Local } from '@/utils/storage';
import { PrevLoading } from '@/utils/loading.js';
	import {login} from "@/api/index"  //导入api目录下的接口文件，并在{}中写使用的接口
export default {
  name: 'login',
  data() {
    return {
     quotationsList,
	 quotations: {},
	 ruleForm: {
	 	username: '',
	 	password: '',
	 	// code: '1234',
	 	group:'pc'
	 },
	 time: {
	 	txt: '',
	 	fun: null,
	 },
	 submit: {
	 	loading: false,
	 },
    };
  },
  components: {
   
  },
  mounted() {
  	this.initRandomQuotations();
  },
  methods: {
    // 随机语录
    initRandomQuotations() {
		console.log(this.quotationsList)
		console.log(this.quotations)
    	this.quotations = this.quotationsList[Math.floor(Math.random() * this.quotationsList.length)];
    },
	login(){
		this.submit.loading = true;
		PrevLoading.start();
		login(this.ruleForm).then((res) => {
		 		console.log(res)
				Local.set('token',res.data.access_token)
				Local.set('user',res.data.member)
				this.$message.success(res.message);
				this.$router.push({
					path:'index'
				})
		 	})
		 	.catch((res) => {
				console.log(res)
				PrevLoading.done();
				this.submit.loading = false;
		 		this.$message.error(res.message);
		 	});
		// loginApi.signIn(this.ruleForm).then(async (res) => {
		// 		console.log(res)
		// 		Session.set('token', res.data.access_token);
		// 		Local.set('token',res.data.access_token)
		// 		Local.set('user',this.ruleForm)
		// 		this.$router.push('/');
		// 	})
		// 	.catch((res) => {
		// 		this.$message.error(res.message);
		// 		this.submit.loading = false;
		// 	});
	},
  },
}
</script>
<style scoped lang="scss">
	@keyframes logoAnimation {
		0% {
			transform: scale(0);
		}
		80% {
			transform: scale(1.4);
		}
		100% {
			transform: scale(1);
		}
	}
.login {
	height: 100%;
	width: 100%;
	overflow: hidden;
	display: flex;
	position: relative;
	.vue-particles {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: radial-gradient(ellipse at top left, rgba(105, 155, 200, 1) 0%, rgba(181, 197, 216, 1) 57%);
	}
	.login-weaper {
		margin: auto;
		height: 500px;
		display: flex;
		box-sizing: border-box;
		position: relative;
		z-index: 1;
		border: none;
		box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
		.login-left {
			width: 491px;
			padding: 20px;
			font-size: 16px;
			color: var(--prev-color-text-white);
			position: relative;
			background-color: var(--prev-color-primary);
			display: flex;
			flex-direction: column;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			.login-time {
				width: 100%;
				color: var(--prev-color-text-white);
				opacity: 0.9;
				font-size: 14px;
				overflow: hidden;
			}
			.login-left-box {
				flex: 1;
				overflow: hidden;
				position: relative;
				z-index: 1;
				display: flex;
				align-items: center;
				padding: 80px 45px;
				.logo {
					font-size: 22px;
					margin-bottom: 15px;
				}
				.leftlogo {
					
					animation: logoAnimation 1.3s ease;
					margin-bottom: 15px;
				}
				.title {
					color: var(--prev-color-text-white);
					font-weight: 300;
					letter-spacing: 2px;
					font-size: 16px;
				}
				.msg {
					color: var(--prev-color-text-white);
					font-size: 13px;
					margin-top: 35px;
					.msg-author {
						opacity: 0.6;
						span:last-of-type {
							margin-left: 15px;
						}
					}
					.msg-txt {
						margin-top: 15px;
						line-height: 22px;
					}
				}
			}
		}
		@keyframes zwyHover3 {
			0% {
				transform: scale(1, 1);
			}
		
			33% {
				transform: scale(1, 1.2);
			}
		
			66% {
				transform: scale(1.2, 1);
			}
		
			100% {
				transform: scale(1, 1);
			}
		}
		
		.zwyHover3 {
			--count: infinite;
			animation: zwyHover3 .3s var(--count) !important;
		}
		.login-right {
			width: 491px;
			padding: 20px;
			position: relative;
			align-items: center;
			display: flex;
			overflow: hidden;
			background-color: var(--prev-bg-white);
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		
			
			.login-main {
				margin: 0 auto;
				width: 70%;
				overflow:hidden;
				
				.rightlogo{
					cursor: pointer;
					// animation: logoAnimation 1.3s ease;
					position: absolute;
					bottom:0px;
					right:0px;
					&:active{
						--count: infinite;
						animation: zwyHover3 .3s var(--count) !important;
					}
				}
				.login-title {
					animation: logoAnimation 1.3s ease;
					color: var(--prev-color-text-primary);
					margin-bottom: 40px;
					font-weight: 500;
					font-size: 22px;
					text-align: center;
					letter-spacing: 4px;
				}
				.login-form {
					margin: 10px 0;
					i {
						color: var(--prev-color-text-primary);
					}
					.el-form-item {
						margin-bottom: 20px !important;
						.login-code {
							display: flex;
							align-items: center;
							justify-content: space-around;
							margin: 0 0 0 10px;
							user-select: none;
							.login-code-img {
								margin-top: 2px;
								width: 100px;
								height: 38px;
								border: 1px solid var(--prev-border-color-base);
								color: var(--prev-color-text-primary);
								font-size: 14px;
								font-weight: 700;
								letter-spacing: 5px;
								line-height: 38px;
								text-indent: 5px;
								text-align: center;
								cursor: pointer;
								transition: all ease 0.2s;
								border-radius: 4px;
								&:hover {
									border-color: var(--prev-border-color-hover);
									transition: all ease 0.2s;
								}
							}
						}
						.login-submit {
							width: 100%;
							letter-spacing: 2px;
						}
					}
				}
				.login-menu {
					margin-top: 30px;
					width: 100%;
					text-align: left;
					a {
						color: var(--prev-color-text-secondary);
						font-size: 12px;
						margin: 0 8px;
						text-decoration: none;
						&:hover {
							color: var(--prev-color-primary);
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}
</style>
