import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import login from '../views/login.vue'
import { PrevLoading } from '@/utils/loading.js';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { Session,Local } from '@/utils/storage';
Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    name: 'index',
    component: index,
	 meta: {
	   title: '首页'
	  }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
	meta: {
	  title: '登录'
	 }
  }
]
// 延迟关闭进度条
export function delayNProgressDone(time = 300) {
	setTimeout(() => {
		NProgress.done();
	}, time);
}
const router = new VueRouter({
  routes,
  mode: 'history'
})
PrevLoading.start();


// 路由加载前
router.beforeEach((to, from, next) => {
	console.log(to,from,next)
	NProgress.configure({ showSpinner: false });
	if (to.meta.title && to.path !== '/login') NProgress.start();
	let token = Local.get('token');
	if (to.path === '/login' && !token) {
		console.log(1)
		NProgress.start();
		next();
		delayNProgressDone();
	} else {
		if (!token) {
			console.log(2)
			NProgress.start();
			next('/login');
			// router.push(
			// {
			// 	path:'login'
			// })
			Local.clear();
			delayNProgressDone();
			return;
		} else if (token &&( to.path === '/login'|| to.path === '/' )) {
			console.log(3)
			next('/index');
			delayNProgressDone();
	
		} else {
			console.log(4)
				next();
				delayNProgressDone(0);
			
		}
	}
});

// 路由加载后
router.afterEach(() => {
	PrevLoading.done();
});
export default router
