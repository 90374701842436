<template>
	<div class="page">
		<div class="box flex">
			<div class="left">
				<audio controls="controls" :src="sound" ref="audio" style="display: none;" ></audio>
				<img src="../../src/assets/login_logo.png" alt="" style="width: 120px;margin-top: 10px;">
				<img :src="info.head_portrait?info.head_portrait:require('@/assets/image_.png')" alt="" class="avatar">
				<div class="name" @click="stop">坐席工号：{{info.username}}</div>
				<!-- 菜单区域 -->
				<div class="menu-box">
					<div class="menu-item "  @click="changeIndex=0">
						<div class="menu-item-top flex align-center" v-if="changeIndex===0" @click.stop="changeIndex=5">
							<img class="menu-icon" src="../assets/img-1.png" alt="">
							<div class="menu-text" style="color: #2281FF;">{{member.call_status==0?'空闲':'通话中'}}</div>
							<img class="menu-down" src="../assets/xiala.png" alt="">
						</div>
						<div class="menu-item-top flex align-center" v-if="changeIndex!=0">
							<img class="menu-icon" src="../assets/img-3.png" alt="">
							<div class="menu-text" style="color: #333;">{{member.call_status==0?'空闲':'通话中'}}</div>
							<img class="menu-down" src="../assets/xiala2.png" alt="">
						</div>
						<div class="child-box" v-if="changeIndex===0&&member.call_status==1">
							<div>用户id:{{callUser?callUser.uid.slice(0,7)+(callUser.uid.length>7?'...':''):'...'}}</div>
							<div>用户昵称:{{callUser?callUser.name.slice(0,7)+(callUser.name.length>7?'...':''):'...'}}
							</div>
						</div>
					<!-- 	<div class="child-box" >
							用户ID:{{callUser?callUser.name.slice(0,7)+(callUser.name.length>7?'...':''):'...'}}
						</div> -->
					</div>
					<div class="menu-item " @click="changeIndex=1,getCallRecord()">
						<div class="menu-item-top flex align-center" v-if="changeIndex==1" @click.stop="changeIndex=5">
							<img class="menu-icon" src="../assets/img-4.png" alt="">
							<div class="menu-text" style="color: #2281FF;">历史服务记录</div>
							<img class="menu-down" src="../assets/xiala.png" alt="">
						</div>
						<div class="menu-item-top flex align-center" v-if="changeIndex!=1">
							<img class="menu-icon" src="../assets/img-2.png" alt="">
							<div class="menu-text">历史服务记录</div>
							<img class="menu-down" src="../assets/xiala2.png" alt="">
						</div>
						<!-- <div v-infinite-scroll="getCallRecord" style="overflow:auto;height: 580px;" v-if="changeIndex==1">
							<div class="no_child-box" v-for="(item,index) in 300" >
								<div>用户ID:{{item}}</div>
								<div>
									通话时长:{{item}}
								</div>
								
							</div>
						</div> -->
					</div>
				</div>
				<!-- 排队区域 -->
				<div class="rank-box">
					<div class="top-box flex align-center">
						排队情况
					</div>
					<div class="bottom-box" v-infinite-scroll="" style="overflow:auto;height: auto; max-height: 350px;">
						<div class="bottom-item flex align-center justify-between" v-for='(item,index) in list'>
							<div class="class-name">{{item.name}}</div>
							<div class="number">{{item.lineupCount}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="right">
				<div class="top flex align-center justify-between">
					<div class="top-left flex align-center" @click="clockend">
						<img src="../assets/logo.png" alt="" class="logo">
						<div class="title">视频客服系统</div>
					</div>
					<div class="top-center flex align-center">
						<div class="flex status-box">
							<div class="status-item" @click="changeStatus(1)">
								<img :src="member.csd_status==0?img6:dimg6" alt="" class="icon-ready">
								<div style="color: #2281FF;" v-if="member.csd_status==0">就绪</div>
								<div  v-else>就绪</div>
							</div>
							<div class="status-item" @click="changeStatus(0)">
								<img :src="member.csd_status==0?dimg7:img7" alt="" class="icon-busy">
								<div style="color: #2281FF;" v-if="member.csd_status==1">示忙</div>
								<div v-else>示忙</div>
							</div>
						</div>
						
						<lzcClock  @clockend="clockend" :start_num='start_num' ref="myClock" /> 
						<el-tag class="ml10">{{member.csd_status==1?'就绪中':'示忙中'}}</el-tag>
						
					</div>
					<div class="top-right flex" @click="logout">
						<img src="../assets/img-8.png" alt="" class="icon-close">
					</div>
				</div>
				<!-- -->
				<div class="flex justify-center align-center" style="height: 93.5vh;" v-show="changeIndex!=1" >
					<div style="font-size: 32px;position: absolute;top: 150px;" v-if="chatStatus==1">{{callUser?callUser.name:''}}邀请您进行视频通话</div>
					<div style="width: 100%; height:100%; border: 1px solid rgba(0, 0, 0, 0.06);" >
						<!-- <TUICallKit :beforeCalling="beforeCalling" :afterCalling="afterCalling"
							:onMinimized="onMinimized" :allowedMinimized="true" :allowedFullScreen="true"
							:videoDisplayMode="VideoDisplayMode.CONTAIN" a
							:videoResolution="VideoResolution.RESOLUTION_720P" @kicked-out="handleKickedOut"
							@status-changed="handleStatusChanged" /> -->
							
							<chat ref="chat" :status="chatStatus" @exitRoom="exitRoom" @enterRoom="enterRoom"></chat>
					</div>

					<!-- <img src="../assets/img-9.png" alt="" style="position: absolute;top: 45vh;" v-if="type=='idle'"> -->
				</div>
				<div class="" style="height: 93.5vh;" v-show="changeIndex==1">
					<!-- <img src="../assets/img-9.png" alt="" style="position: absolute;top: 45vh;" v-if="type=='idle'"> -->
					<el-card shadow="hover" header="">
						
						<el-table :data="tableData.data" style="width: 100%;z-index: 1;height:83.5vh;">
							<el-table-column type="index" width="70" label='序号' align='center'>
							</el-table-column>
							<el-table-column prop="uid" label="手机号" width="150">

							</el-table-column>
							<el-table-column prop="name" label="用户姓名" width="100">

							</el-table-column>
							<el-table-column prop="channel" label="来源" width="80">
							
							</el-table-column>
							<el-table-column prop="member.username" label="坐席ID" width="100">

							</el-table-column>
							<el-table-column prop="start_time" label="开始时间" width="180">

							</el-table-column>
							<el-table-column prop="reply_time" label="答复时间" width="180">

							</el-table-column>
							<el-table-column prop="end_time" label="结束时间" width="180">

							</el-table-column>
							<el-table-column prop="call_duration" label="通话时长" width="80">

							</el-table-column>
							<el-table-column prop="lineup_duration" label="排队时长" width="80">

							</el-table-column>
							<el-table-column prop="sort" label="呼叫状态" width="180">
								<template slot-scope="scope">
									<div class="">
										<el-tag type="danger" v-if="scope.row.call_status==-4">{{scope.row.call_describe}}</el-tag>
										<el-tag type="success" v-else-if="scope.row.call_status==1">{{scope.row.call_describe}}</el-tag>
										<el-tag type="warning" v-else-if="scope.row.call_status==3">{{scope.row.call_describe}}</el-tag>
										<el-tag type="info" v-else>{{scope.row.call_describe}}</el-tag>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="csd_describe" label="备注" width="150"5
							
							</el-table-column>
							
							<el-table-column fixed="right" width="150" label="操作">
								<template slot-scope="scope">
									<el-button @click="play(scope.row.file_id)" type="text"
										size="small"  v-if="scope.row.file_id">播放</el-button>
									<el-button @click="xiazai(scope.row.playback_url)" type="text"
										size="small" v-if="scope.row.playback_url">下载</el-button>
										<el-button @click="sendwork(scope.row.id)" type="text"
											size="small" >备注</el-button>
								</template>
							</el-table-column>
						</el-table>

						<div class="block mt20" style="">
							<el-pagination :page-sizes="[12]" :page-size="tableFrom.pageSize"
								:current-page="tableFrom.page" layout="total, sizes, prev, pager, next, jumper"
								:total="tableData.total" @size-change="handleSizeChange" @current-change="pageChange" />
						</div>
					</el-card>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	var that
	import {
		Session,
		Local
	} from '@/utils/storage';
	import {
		formatDate,
		formatAxis
	} from '@/utils/formatTime';
	// import {
	// 	TUICallKit,
	// 	TUICallKitServer,
	// 	TUICallType,
	// 	VideoDisplayMode,
	// 	VideoResolution
	// } from "@/components/TUICallKit";
	import lzcClock from '@/components/clock';
	
	import chat from "../../components/chat.vue"
	
	import {
		products,
		login,
		info,
		logout,
		callrecord,
		recordplayurl,
		recorddescribe
	} from "@/api/index" //导入api目录下的接口文件，并在{}中写使用的接口
	export default {
		name: 'App',
		data() {
			return {
				sound:require('../assets/11000.wav'),
				time: {
					txt: '',
					fun: null,
				},
				SDKAppID: 1400812583,
				userID: '',
				userSig: '',
				callUserID: '',
				// VideoDisplayMode,
				// VideoResolution,
				type: 'idle',
				info: {},
				record: [],
				changeIndex: 0,
				list: [],
				member: {
					csd_status: 0,
					call_status: 0
				},
				callUser: {
					name:''
				},
				dimg6: require('../assets/dimg6.png'),
				img6: require('../assets/img-6.png'),
				dimg7: require('../assets/dimg-7.png'),
				img7: require('../assets/img-7.png'),
				nowId: {},
				timeStatus: 0,
				tableData: {
					data: [],
					total: 0,
				},
				tableFrom: {
					page: 1,
					pageSize: 12
				},
				start_num:0,
				chatStatus:0,
				
			}
		},
		components: {
			// TUICallKit,
			chat,
			lzcClock
		},
		mounted() {
			this.socketApi.createWebSocket();
			that = this;
			this.initTime()
			this.getInfo()
			this.getCallRecord()
			// this.socketApi.getSock(this.getConfigResult)
			this.socketLogin()
			this.$refs.myClock.start(); 
			
			// this.init()
			// this.call()
			// this.$tool.showLoading();
			// setTimeout(() => {
			// 	this.$tool.closeLoading();
			// },2000)
		},
		created() {
		},
		destroyed() {
			this.socketApi.closeWebSocket();
			if(this.type=='calling-c2c-video'){
				this.hangup()
			}
			Local.remove('room')
		},
		watch:{
			member(e,l){
				// console.log(e.csd_status)
				if(e.csd_status==l.csd_status) return;
				if(e.csd_status==0){
					this.$refs.myClock.suspend(); 
					this.$refs.myClock.start(); 
				}else{
					this.$refs.myClock.suspend();
					this.$refs.myClock.start(); 
				}
			}
		},
		methods: {
			showGoogle(){
				            // 请求用户授权显示通知
				            Notification.requestPermission().then(function (permission) {
				                if (permission === 'granted') {
				                    // 创建新的通知，并设置标题、内容和图标等选项
				                    var notification = new Notification('来电提示！', {
				                        body: '您有一个新的来电提示！',
				                        icon: '../assets/img-6.png', // 替换为广告图标的 URL
				                    })
				
				                    // 在通知中添加自定义样式
				                    notification.addEventListener('show', function () {
				                        // 创建弹框容器元素
				                        var container = document.createElement('div')
				                        container.classList.add('ad-container')
				
				                        // 创建弹框内容元素
				                        var content = document.createElement('div')
				                        content.classList.add('ad-content')
				                        content.textContent = '您有一个新的用户来电！'
				
				                        // 将内容添加到容器中
				                        container.appendChild(content)
				
				                        // 在页面底部显示弹框
				                        document.body.appendChild(container)
				                    })
				                }
				            })
			
			},
			send(){
				console.log('发送成功')
			},
			enterRoom(){
				this.chatStatus = 2;
				this.$refs.audio.currentTime = 0;
				this.$refs.audio.pause();
				this.timeStatus = 1
				var type = {
					type: "csd.call.status",
					token: Local.get('token'),
					call_status: 2,
					record_id: this.nowId.recordId,
					room_id:Local.get('room')
				}
				this.socketApi.sendSock(type, this.getConfigResult)
			},
			//退出房间
			exitRoom(){
				console.log('是否推出了吼吼吼吼吼吼吼吼吼吼吼吼吼吼吼')
				this.chatStatus = 0;
				this.$refs.audio.currentTime = 0;
				this.$refs.audio.pause();
				Local.remove('room')
				if (this.timeStatus == 1) {
					var type = {
						type: "csd.call.status",
						token: Local.get('token'),
						call_status: 3,
						record_id: this.nowId.recordId
					}
				} else {
					var type = {
						type: "csd.call.status",
						token: Local.get('token'),
						call_status: -4,
						record_id: this.nowId.recordId
					}
				}
				this.socketApi.sendSock(type, this.getConfigResult)
				this.timeStatus = 0
				if (this.nowId.hangupStatus == 1) {
					console.log('自动就绪')
					var type = {
						type: "csd.status",
						token: Local.get('token'),
						csd_status: 1
					}
					this.socketApi.sendSock(type, this.getConfigResult)
				}
			},
			sendwork(id){
			        this.$prompt('请输入备注', '提示', {
			          confirmButtonText: '确定',
			          cancelButtonText: '取消',
			        }).then(({ value }) => {
						recorddescribe({id:id,csd_describe:value}).then((res) => {
						console.log(res)
						this.$message.success(res.message);
							this.getCallRecord()
					})
					.catch((res) => {
						this.$message.error(res.message);
					});
			          // this.$message({
			          //   type: 'success',
			          //   message: '你的邮箱是: ' + value
			          // });
			        }).catch(() => {
			          // this.$message({
			          //   type: 'info',
			          //   message: '取消输入'
			          // });       
			        });
			      },
			stop(e){
				console.log(123)
			
			},
			clockend(res){
				// this.$refs.myClock.start(); 
			},
			play(id) {
				recordplayurl({file_id:id}).then((res) => {
						console.log(res)
						window.open(res.data.url);
					})
					.catch((res) => {
						this.$message.error(res.message);
					});
			},
			xiazai(url) {
				
				window.open(url);
			},
			handleSizeChange(val) {
				this.tableFrom.pageSize = val;
				this.getCallRecord('');
			},
			pageChange(page) {
				this.tableFrom.page = page;
				this.getCallRecord('');
			},
			// 初始化左上角时间显示
			initTime() {
				this.time.txt = formatDate(new Date(), 'YYYY-mm-dd HH:MM:SS WWW QQQQ');
				this.time.fun = setInterval(() => {
					this.time.txt = formatDate(new Date(), 'YYYY-mm-dd HH:MM:SS WWW QQQQ');
				}, 1000);
			},
			changeStatus(type) {
				if (this.member.call_status == 1) {
					this.$message.warning('正在通话请勿操作状态')
					return;
				}
				if (type == 0) {
					var type = {
						type: "csd.status",
						token: Local.get('token'),
						csd_status: type
					}
					this.socketApi.sendSock(type, this.getConfigResult)
				}
				if (type == 1) {
					var type = {
						type: "csd.status",
						token: Local.get('token'),
						csd_status: type
					}
					this.socketApi.sendSock(type, this.getConfigResult)
				}
			},
			getConfigResult(res) {
				console.log(res, 'socket信息')
				if (res.type == 'csd.login.msg') {
					this.userID = res.data.UserID
					this.userSig = res.data.UserSig
					// setTimeout(function() {
					// 	that.init()
					// }, 500)
				}
				if (res.type == 'csd.skill.list') {
					this.list = res.skill
					this.member = res.member
					this.callUser = res.record
				}
				if (res.type == 'csd.call.open') {
					this.nowId = res
					this.chatStatus = 1;
					this.$refs.audio.currentTime = 0;
					this.$refs.audio.play();
					this.showGoogle()
					this.changeCall()
				
				}
				if (res.type == 'csd.skill.hang') {
				this.$refs.audio.currentTime = 0;
				this.$refs.audio.pause();
				this.chatStatus = 0;
				}
				if (res.type == 'csd.call.hang') {
				this.$refs.audio.currentTime = 0;
				this.$refs.audio.pause();
				this.chatStatus = 0;
				if (this.nowId.hangupStatus == 1) {
					var type = {
						type: "csd.status",
						token: Local.get('token'),
						csd_status: 1
					}
					this.socketApi.sendSock(type, this.getConfigResult)
				}
				}
				if (res.type == 'csd.status.msg'&&res.code==422) {
					this.$message.error(res.message)
				}
				
			},
			socketLogin() {
				var type = {
					type: "csd.login",
					token: Local.get('token')
				}
				this.socketApi.sendSock(type, this.getConfigResult)

			},
			getCallRecord() {
				callrecord(this.tableFrom).then((res) => {
						console.log(res)
						this.record = res.data.list
						this.tableData.data = res.data.list;
						this.tableData.total = Number(res.data.totalCount);
					})
					.catch((res) => {
						this.$message.error(res.message);
					});
			},
			// 坐席登出
			logout() {
				var that = this;
				this.$confirm('此操作将退出系统, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					logout().then((res) => {
							console.log(res)

							Local.clear()
							setTimeout(function() {
								that.$router.push({
									path: 'login'
								})
							}, 500)
						})
						.catch((res) => {
							this.$message.error(res.message);
						});
				}).catch(() => {

				});


			},
			// 获取坐席信息
			getInfo() {
				info().then((res) => {
						console.log(res)
						this.info = res.data
					})
					.catch((res) => {
						this.$message.error(res.message);
					});

			},
			send() {
				products({
						id: 1232
					}).then((res) => {
						console.log(res)
						this.$message.success(res.message);
					})
					.catch((res) => {
						this.$message.error(res.message);
					});

			},
			async init() {
				try {
					// await TUICallKitServer.init({
					// 	SDKAppID: this.SDKAppID,
					// 	userID: this.userID,
					// 	userSig: this.userSig
					// 	// tim: this.tim // 如果工程中已有 tim 实例，需在此处传入
					// });
					// alert("初始化完成");
				} catch (error) {
					alert(`初始化失败，原因：${error}`);
				}
			},
			async call() {
				try {
					// 1v1 通话
					// await TUICallKitServer.call({
					// 	userID: this.callUserID,
					// 	type: TUICallType.VIDEO_CALL
					// });
					// 群组通话
					// TUICallKitServer.groupCall({ userIDList: ["xxx"], groupID: "xxx", type: TUICallType.VIDEO_CALL }); 
				} catch (error) {
					alert(`拨打失败，原因：${error}`);
				}
			},
			beforeCalling(type, error) {
				this.changeIndex=0
				console.log("通话即将开始", type, error);
			},
			afterCalling(type) {
				console.log("通话已结束", type);
				this.$refs.audio.currentTime = 0;
				this.$refs.audio.pause();
				Local.remove('room')
				if (this.timeStatus == 1) {
					var type = {
						type: "csd.call.status",
						token: Local.get('token'),
						call_status: 3,
						record_id: this.nowId.recordId
					}
				} else {
					var type = {
						type: "csd.call.status",
						token: Local.get('token'),
						call_status: -4,
						record_id: this.nowId.recordId
					}
				}
				this.socketApi.sendSock(type, this.getConfigResult)
				this.timeStatus = 0
				if (this.nowId.hangupStatus == 1) {
					var type = {
						type: "csd.status",
						token: Local.get('token'),
						csd_status: 1
					}
					this.socketApi.sendSock(type, this.getConfigResult)
				}
			},
			onMinimized(oldStatus, newStatus) {
				console.log("最小化状态变更: " + oldStatus + " -> " + newStatus);
			},
			handleKickedOut() {
				console.error("The user has been kicked out");
			},
			handleStatusChanged(args) {
				var that = this;
				if (args.newStatus == 'be-invited') {
					this.$refs.audio.currentTime = 0;
					this.$refs.audio.play();
					this.changeCall(args.newStatus)
				}
				this.type = args.newStatus
				if (args.newStatus == 'calling-c2c-video') {
					this.$refs.audio.currentTime = 0;
					this.$refs.audio.pause();
					this.timeStatus = 1
					var type = {
						type: "csd.call.status",
						token: Local.get('token'),
						call_status: 2,
						record_id: this.nowId.recordId,
						room_id:Local.get('room')
					}
					this.socketApi.sendSock(type, this.getConfigResult)
				}
				// if(this.type=='be-invited') {
				// 	setTimeout(function(){
				// 		that.accept()
				// 	},1000) 
				// }
				const {
					oldStatus,
					newStatus
				} = args;
				console.log("通话状态变更: " + oldStatus + " -> " + newStatus);
			},
			changeCall() {
				console.log(this.nowId.autoAnswer)
				if (this.nowId.autoAnswer == 1) {
					console.log('自动接听')
					// this.$refs.chat.initRoom()
						this.$refs.chat.joinRoom({
						roomId:this.nowId.room_id,
						userId:this.userID,
						userSig:this.userSig
					});
				}else{
					console.log('不自动接听')
					this.$refs.chat.initRoom({
						roomId:this.nowId.room_id,
						userId:this.userID,
						userSig:this.userSig
					})
				}
			},
			async accept() {
				try {
					await TUICallKitServer.accept();
					// var type={type: "csd.call.status",token:Local.get('token'),call_status:2,record_id:this.nowId.recordId}
					// this.socketApi.sendSock(type,this.getConfigResult)
					// alert("已自动接听");
				} catch (error) {
					alert(`自动接听失败，原因：${error}`);
				}
			},
			async reject() {
				try {
					await TUICallKitServer.reject();
					alert("已自动拒绝");
				} catch (error) {
					alert(`自动拒绝失败，原因：${error}`);
				}
			},
			async hangup() {
				try {
					await TUICallKitServer.hangup();
					alert("已自动挂断");
				} catch (error) {
					alert(`自动挂断失败，原因：${error}`);
				}
			}
		},
	}
</script>

<style lang="scss" scoped>
	@import "../theme/public.scss";

	.page {
		width: 1920px;
		margin: 0px auto;
	}

	.row {
		width: 1920px;
	}

	::-webkit-scrollbar {
		display: none !important;
	}

	.box {
		background: #F0F2F8;

		.left {
			position: relative;
			width: 200px;
			display: flex;
			flex-flow: column;
			align-items: center;
			background: #fff;
			border: 1px solid rgba(0, 0, 0, 0.06);

			.avatar {
				width: 48px;
				height: 48px;
				border-radius: 50%;
				// background: red;
				margin: 0px auto;
				margin-top: 60px;
			}

			.name {
				color: #000A15;
				font-size: 16px;
				font-weight: 900;
				margin-top: 18px;
			}

			.menu-box {
				margin-top: 10px;
				height: 530px;
				width: 200px;

				.menu-item {
					cursor: pointer;
					

					&.select {
						.menu-text {
			
							color: #2281FF;
						}
					}

					.menu-item-top {
						width: 100%;
						height: 60px;
						padding-left: 27px;
					}

					.child-box {
						padding-left: 39px;
						width: 200px;
						background: #F0F2F8;
						color: #2281FF;
						height: 48px;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						justify-content: center;
						font-size: 14px;
						border-right: 4px solid #2281FF;
					}

					.no_child-box {
						padding: 10px 0;
						line-height: 1.5;
						padding-left: 69px;
						width: 200px;
						background: #F0F2F8;
						color: #333;
						border-bottom: 1px solid rgba(0, 0, 0, 0.06);
						// height: 48px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: flex-start;
						font-size: 18px;
						// border-right: 4px solid #2281FF;
					}
				}

				.menu-icon {
					width: 16px;
					height: 16px;
				}

				.menu-text {
					margin: 0px 10px;
				}

				.menu-down {
					margin-top: 2px;
					width: 15px;
					height: 15px;
				}
			}

			.rank-box {
				position: absolute;
				left: 0;
				bottom: 0px;

				.top-box {

					width: 200px;
					height: 50px;
					background: #2281FF;
					color: #fff;
					padding-left: 27px;
				}

				.bottom-box {
					height: 324px;
					width: 200px;
					background: #e8f2ff;
					padding-left: 27px;
					padding-right: 39px;
					padding-top: 29px;

					.bottom-item {
						margin-bottom: 35px;
					}
				}
			}
		}

		.right {
			width: 1819px;

			.top {
				width: 100%;
				height: 60px;
				border: 1px solid rgba(0, 0, 0, 0.06);
				background: #fff;
				padding: 0px 40px;

				.top-left {
					.logo {
						width: 25px;
						height: 25px;
						margin-right: 10px;
					}

					.title {
						font-size: 20px;
						font-weight: 700;
						color: #000;
					}
				}

				.top-center {
					font-size: 14px;
					color: #646464;

					.status-box {
						position: relative;
						margin-right: 35px;

						&::after {
							content: "";
							display: block;
							position: absolute;
							right: 0px;
							top: 30%;
							height: 32px;
							width: 2px;
							background: #C9CDD6;
						}

						.status-item {
							cursor: pointer;
							margin: 0px 35px;
						}
					}

					.icon-ready {
						width: 20px;
						height: 20px;
						margin-bottom: 5px;
					}

					.icon-busy {
						width: 20px;
						height: 20px;
						margin-bottom: 5px;
					}
				}

				.top-right {
					cursor: pointer;
					width: 20px;
					height: 20px;
				}
			}
		}
	}
</style>