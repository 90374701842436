<template>
	<div class="clock">
		<div style="font-size: 16px;">{{hour}}:</div>
		<div style="font-size: 16px;">{{minute}}:</div>
		<div style="font-size: 16px;">{{second}}</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				hour:"00",
				minute:"00",
				second:"00",
				totalCount:0,
				timer:null,
				temp_start_num:0,
			};
		},
		props:{
			lists:{
				type:Object
			},
			start_num:{
				type:Number,
				default:0
			}
		},
		created() {
			console.log('开始时间',this.start_num)
			let _this = this;
			_this.temp_start_num = _this.start_num
			let count = _this.start_num;
			_this.second = _this.showNum(count % 60);
			_this.hour = _this.showNum(parseInt(count / 60 / 60));
			_this.minute = _this.showNum(parseInt(count / 60) % 60);
		},
		methods:{
			end(){
				this.is_show = false;
				clearInterval(this.timer);
				this.$emit('clockend',this.totalCount);
				this.temp_start_num =  this.start_num
			},
			// 暂停
			suspend(){
				let _this = this;
				_this.is_show = false;
				clearInterval(_this.timer);
				// _this.temp_start_num =  _this.totalCount
				_this.temp_start_num =  0
			},
			showNum(num) {
				if (num < 10) {
					return '0' + num
				}
				return num
			},
			start(){
				let _this = this;
				// let count = 0;
				let count =_this.temp_start_num;
				_this.timer = setInterval(function(){
					count++;
					_this.second = _this.showNum(count % 60);
					_this.hour = _this.showNum(parseInt(count / 60 / 60));
					_this.minute = _this.showNum(parseInt(count / 60) % 60);
					_this.totalCount = count;
				},1000);
				this.is_show = true;
			}
		}
	}
</script>

<style lang="scss">
	.clock{
		display: flex;
		align-items: center;
		font-size: 18px;
	}
</style>
