
import request from "@/utils/request";  //导入封装请求的js文件
 
export function products(params) {
  return request({
    url: "bless/getresortlist",  //接口路径
    method: "POST",  //接口方法
    headers: { 'Content-Type': 'multipart/form-data' }, //给接口添加请求头
    params  //接口参数
  });
}
 
export function login(data) {
  return request({
    url: "call/login",
    method: "post",
    data
  });
  }
  export function info(data) {
    return request({
      url: "call/info",
      method: "post",
      data
    });
    }
	export function logout(data) {
	  return request({
	    url: "call/logout",
	    method: "post",
	    data
	  });
	  }
	  export function callrecord(data) {
	    return request({
	      url: "call/call-record",
	      method: "post",
	      data
	    });
	    }
		export function recordplayurl(data) {
		  return request({
		    url: "call/record-play-url",
		    method: "post",
		    data
		  });
		  }
		  export function recorddescribe(data) {
		    return request({
		      url: "call/call-record-describe",
		      method: "post",
		      data
		    });
		    }