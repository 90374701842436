import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/theme/index.scss';
import Particles from 'vue-particles';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// WebSocket封装方法
import * as socketApi from './utils/socket'
Vue.prototype.socketApi = socketApi

Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
 /* 路由发生变化修改页面title */
 if (to.meta.title) {
  document.title = to.meta.title
 }
 next()
})
Vue.use(Particles);
Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
