<template>
	<div class="chat-box">
			<div :class="classType == 1? 'selfCamera' : 'serveCamera' "  id="selfCamera" @click="toggleClass(1)"></div>
					<div :class="classType == 1? 'serveCamera' : 'selfCamera' " id="serveCamera" @click="toggleClass(2)" ></div>
			<!-- <div style="position: absolute;right: 182px;top: 182px;z-index: 9999999;">
				<img src="../src/assets/quanping.png" alt="" style="width: 30px;height: 30px;" v-if="status==2" @click="changeClass">
			</div> -->
		
		<div class="dis-ali" style="justify-content: center;flex-direction: column;height: 93.5vh;" v-if="status==0">
			<img src="../src/assets/img-9.png" style=""  alt=""> 
		</div>
		<!-- <div class="dis-ali" style="justify-content: center;margin-top: 280px;" v-if="status==1">
			<img src="../src/assets/img-9.png" style=""  alt="">
		</div> -->
		<div class="action-box" v-if="status == 2">
			<div class="icon icon-open" v-if="audioOpen" @click="toggleAudio">
				<img src="../src/assets/yuyin_open.png" alt="">
			</div>
			<div class="icon icon-close" v-else  @click="toggleAudio">
				<img src="../src/assets/yuyin_close.png" alt="">
			</div>
			<div class="icon close-btn" @click="exitRoom">
				<img src="../src/assets/guanduan.png" alt="">
			</div>
			<div class="icon icon-open" v-if="videoOpen" @click="toggleVideo">
				<img src="../src/assets/shipin_open.png" alt="">
			</div>
			<div class="icon icon-open" v-else @click="toggleVideo">
				<img src="../src/assets/shipin_close.png" alt="">
			</div>
		</div>
		<div class="action-box" v-if="status == 1">
			<div class="icon close-btn" @click="exitRoom">
				<img src="../src/assets/guanduan.png" alt="">
			</div>
			<div class="icon close-btn" @click="joinRoom(room)">
				<img src="../src/assets/jieting.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	import TRTC from 'trtc-sdk-v5';
	
	let trtc;
	export default{
		data(){
			return{
				SDKAppID: 1400812583,
				audioOpen:true,
				videoOpen:true,
				room:false,
				classType:1
			}
		},
		props:{
			status:0,// 0 -> 用户端 1客服端接听 2 通话中  3通话结束
		},
		mounted() {
			trtc = TRTC.create();
			// 监听
			trtc.on(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, ({ userId, streamType }) => {
				// 为了播放视频画面，您需在 DOM 中放置一个 HTMLElement，可以是一个 div 标签，假设其 id 为 `${userId}_${streamType}`
				let view = document.getElementById("serveCamera");
				trtc.startRemoteVideo({ userId, streamType, view,option: { fillMode: 'contain' }  });
			});
			trtc.on(TRTC.EVENT.REMOTE_USER_EXIT, event => {
					
							this.exitRoom();
						});
		},
		methods:{
			toggleClass(type){
							if(type == this.classType){
								this.classType = this.classType == 1 ? 2 : 1;
							}
							
						}, 
			async exitRoom(){
				this.classType=1
				await trtc.exitRoom(); 
				// trtc.destroy();
				await trtc.stopLocalVideo();
				this.videoOpen = false;
				await trtc.stopLocalAudio();
				this.audioOpen = false;
				this.$emit('exitRoom')
			},
			initRoom(room){
				this.room = room;
			},
			//进入房间开始推流
			async joinRoom(room){
				await trtc.enterRoom({ roomId:parseInt(room.roomId), scene:'rtc', sdkAppId:this.SDKAppID, userId:room.userId, userSig:room.userSig });
				let view = document.getElementById("selfCamera");
				await trtc.startLocalVideo({ view, option: { profile: '720p',fillMode:'contain' } });
				await trtc.startLocalAudio();
				this.audioOpen = true;
				this.videoOpen = true;
				this.$emit('enterRoom')
			},
			async toggleVideo(){
				if(this.videoOpen){
					await trtc.stopLocalVideo();
					this.videoOpen = false;
				}else{
					let view = document.getElementById("selfCamera");
					await trtc.startLocalVideo({ view });
					this.videoOpen = true;
				}
			},
			async toggleAudio(){
				if(this.audioOpen){
					await trtc.stopLocalAudio();
					this.audioOpen = false;
				}else{
					await trtc.startLocalAudio();
					this.audioOpen = true;
				}
			}
		},
	}
</script>

<style lang="scss" scoped>
	.chat-box{
		width: 100%;
		height: 100%;
		// background: #ddd;
		position: relative;
		.selfCamera{
			cursor: pointer;
			position: absolute;
			width: 300px;
			height: 300px;
			right: 10px;
			top: 10px;
			z-index: 1888;
		}
		.serveCamera{
			// height:93.45vh;
			// width: 89.6vw;
			position: absolute;
			left: 0px;
			right: 0px;
			bottom: 0px;
			top: 0px;
			z-index: 1777;
			// background: #000;
		}
		
		.action-box{
			width: 100%;
			height: 200px;
			position: absolute;
			bottom: 0px;
			z-index: 1990;
			display: flex;
			flex-flow:  row nowrap;
			align-items: center;
			justify-content: space-evenly;
			
			.icon{
				cursor: pointer;
				width: 70px;
				height: 70px;
				border-radius: 50%;
				display:flex;
				align-items: center;
				justify-content: center;
				
				img{
					width: 50px;
					height: 50px;
				}
				
				&.close-btn{
					background:#fff;
					img{
						width: 70px;
						height: 70px;
					}
				}
			}
			.icon-close{
				background: rgba(0,0,0,0.05);
			}
			.icon-open{
				background: #fff;
			}
		}
	}
</style>